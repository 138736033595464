import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import useSpeech from '../../contexts/SpeechContext';
import theme from '../../theme/theme';
import { ReactComponent as SpeechBubbleArrow } from '../../assets/SpeechCloudArrow.svg';
import { motion } from 'framer-motion';
import { Typography } from '@mui/material';

export default function SpeechBubble({ prompt, onSpeechEnded }) {
    const { Speak } = useSpeech();

    useEffect(() => {
        Speak(prompt.ssml, onSpeechEnded);
    }, []);

    return (
        <Box
            sx={{
                position: 'relative',
                pl: '22px',
                mb: 2,
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    px: 2,
                    py: 1,
                    borderRadius: '24px',
                    background: theme.palette.secondaryContainer.main,
                    color: theme.palette.secondaryContainer.contrastText,
                    width: '100%',

                    [theme.breakpoints.up('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            fontSize: 24,
                            fontWeight: 700,
                        },
                    }}
                >
                    <Typography variant="body1">{prompt.text}</Typography>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: -22,
                        height: 22,
                        zIndex: -1,
                    }}
                >
                    <SpeechBubbleArrow width="45" height="22" />
                </Box>
            </Box>
        </Box>
    );
}
