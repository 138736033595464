import React, { useState } from 'react';
import useScript from '../../../contexts/ScriptContext';
import ChatInput from '../../common/ChatInput';

export default function TheName({ branches }) {
    const [name, setName] = useState('');

    const { refetchScript, goto } = useScript();

    const handleNext = async () => {
        const done = await refetchScript({ name });

        if (done) {
            goto(branches[0].goto);
        }
    };

    return <ChatInput value={name} setValue={setName} onSubmit={handleNext} />;
}
