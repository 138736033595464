import React from 'react';
import useLayout from '../../contexts/LayoutContext';
import { Box, Stack, useTheme } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import SoundSwitch from '../common/SoundSwitch';
import useAppSettings from '../../contexts/AppSettingsContext';

export default function Header() {
    const { headerHeight } = useLayout();
    const { muted, setMuted } = useAppSettings();
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: headerHeight,

                background: theme.palette.white.main,
                borderBottom: '1px solid',
                borderBottomColor: theme.palette.outline.main,
            }}
        >
            <Stack
                direction="row"
                spacing={5}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    px: 5,
                    py: 1,
                }}
            >
                <Box
                    sx={{
                        flex: '1',
                    }}
                ></Box>
                <Logo />
                <Box
                    sx={{
                        flex: '1',
                        textAlign: 'right',
                    }}
                >
                    <SoundSwitch
                        checked={!muted}
                        onClick={() => setMuted((val) => !val)}
                    />
                </Box>
            </Stack>
        </Box>
    );
}
