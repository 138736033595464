import { useContext, createContext } from 'react';

const SceneContext = createContext({});

export const SceneProvider = ({ children }) => {
    const scene = 'a';

    // TODO:
    // uses ScriptContext to get the scene
    // pushes the data
    // saves the data to SummaryProvider (and further into LS)
    //

    return (
        <SceneContext.Provider
            value={{
                scene,
            }}
        >
            {children}
        </SceneContext.Provider>
    );
};

export default function useScene() {
    return useContext(SceneContext);
}
