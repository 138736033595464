import { useContext, createContext, useState, useEffect } from 'react';
import useScript from './ScriptContext';
import useAppSettings from './AppSettingsContext';

const LoadingContext = createContext({});
const MIN_LOADING_TIME = 2000; // needed to show the animation if fetching goes too fast :)

export const LoadingProvider = ({ children }) => {
    const [loaderDone, setLoaderDone] = useState(false);
    const [loading, setLoading] = useState(true);

    const { script } = useScript();
    const { token } = useAppSettings();

    useEffect(() => {
        setTimeout(() => {
            setLoaderDone(true);
        }, MIN_LOADING_TIME);
    }, []);

    useEffect(() => {
        if (script && script.length && token && token.value && loaderDone) {
            setLoading(false);
        }
    }, [script, token, loaderDone]);

    return (
        <LoadingContext.Provider
            value={{
                loading,
            }}
        >
            {children}
        </LoadingContext.Provider>
    );
};

export default function useLoading() {
    return useContext(LoadingContext);
}
