import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { useContext, createContext, useState, useEffect } from 'react';

const LayoutContext = createContext({});

export const LayoutProvider = ({ children }) => {
    const theme = useTheme();
    const [headerHeight, setHeaderHeight] = useState(60);
    const [footerHeight, setFooterHeight] = useState(52);

    const mq = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (mq) {
            setHeaderHeight(80);
        } else {
            setHeaderHeight(60);
        }
    }, [mq]);

    return (
        <LayoutContext.Provider
            value={{
                headerHeight,
                footerHeight,
                setFooterHeight,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export default function useLayout() {
    return useContext(LayoutContext);
}
