import { ButtonBase, Box, useTheme } from '@mui/material';
import React from 'react';
import { motion } from 'framer-motion';

export default function SoundSwitch({ checked, onClick }) {
    const theme = useTheme();

    return (
        <ButtonBase
            sx={{
                width: 64,
                height: 32,
                borderRadius: 32,
            }}
            onClick={onClick}
        >
            <Box
                sx={{
                    width: 63,
                    height: 32,
                    borderRadius: 32,
                    position: 'relative',
                }}
                component={motion.div}
                initial={{
                    backgroundColor: checked
                        ? theme.palette.secondary.main
                        : theme.palette.outline.main,
                }}
                animate={{
                    backgroundColor: checked
                        ? theme.palette.secondary.main
                        : theme.palette.outline.main,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 24,
                        height: 24,
                        top: 4,
                        left: 4,
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M3 10V14C3 14.55 3.45 15 4 15H7L10.29 18.29C10.92 18.92 12 18.47 12 17.58V6.41002C12 5.52002 10.92 5.07002 10.29 5.70002L7 9.00002H4C3.45 9.00002 3 9.45002 3 10ZM16.5 12C16.5 10.23 15.48 8.71002 14 7.97002V16.02C15.48 15.29 16.5 13.77 16.5 12ZM14 4.45002V4.65002C14 5.03002 14.25 5.36002 14.6 5.50002C17.18 6.53002 19 9.06002 19 12C19 14.94 17.18 17.47 14.6 18.5C14.24 18.64 14 18.97 14 19.35V19.55C14 20.18 14.63 20.62 15.21 20.4C18.6 19.11 21 15.84 21 12C21 8.16002 18.6 4.89002 15.21 3.60002C14.63 3.37002 14 3.82002 14 4.45002Z"
                            fill="white"
                        />
                    </svg>
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        width: 24,
                        height: 24,
                        top: 4,
                        left: 35,
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <g opacity="0.5">
                            <path
                                d="M3.63 3.63002C3.24 4.02002 3.24 4.65002 3.63 5.04002L7.29 8.70002L7 9.00002H4C3.45 9.00002 3 9.45002 3 10V14C3 14.55 3.45 15 4 15H7L10.29 18.29C10.92 18.92 12 18.47 12 17.58V13.41L16.18 17.59C15.69 17.96 15.16 18.27 14.58 18.5C14.22 18.65 14 19.03 14 19.42C14 20.14 14.73 20.6 15.39 20.33C16.19 20 16.94 19.56 17.61 19.02L18.95 20.36C19.34 20.75 19.97 20.75 20.36 20.36C20.75 19.97 20.75 19.34 20.36 18.95L5.05 3.63002C4.66 3.24002 4.03 3.24002 3.63 3.63002ZM19 12C19 12.82 18.85 13.61 18.59 14.34L20.12 15.87C20.68 14.7 21 13.39 21 12C21 8.17002 18.6 4.89002 15.22 3.60002C14.63 3.37002 14 3.83002 14 4.46002V4.65002C14 5.03002 14.25 5.36002 14.61 5.50002C17.18 6.54002 19 9.06002 19 12ZM10.29 5.71002L10.12 5.88002L12 7.76002V6.41002C12 5.52002 10.92 5.08002 10.29 5.71002ZM16.5 12C16.5 10.23 15.48 8.71002 14 7.97002V9.76002L16.48 12.24C16.49 12.16 16.5 12.08 16.5 12Z"
                                fill="#0A3F36"
                            />
                        </g>
                    </svg>
                </Box>
                <Box
                    sx={{
                        height: 26,
                        width: 26,
                        borderRadius: 26,
                        background: theme.palette.white.main,
                        position: 'absolute',
                        top: 3,
                    }}
                    component={motion.div}
                    initial={{
                        left: checked ? 34 : 3,
                    }}
                    animate={{
                        left: checked ? 34 : 3,
                    }}
                ></Box>
            </Box>
        </ButtonBase>
    );
}
