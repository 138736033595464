import React from 'react';
import { Button } from '@mui/material';
import useScript from '../../../contexts/ScriptContext';

export default function TheButton({ branches }) {
    const { goto } = useScript();

    const onClick = () => {
        console.log('aaa');
        goto(branches[0].goto);
    };

    return (
        <Button
            onClick={onClick}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            size="large"
        >
            Let&apos;s go
        </Button>
    );
}
