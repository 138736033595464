import React from 'react';
import useLoading from '../contexts/LoadingContext';
import MainLayout from './layout/MainLayout';
import { Box, Stack } from '@mui/material';
import AnimatedAddie from './common/AnimatedAddie';
import { motion } from 'framer-motion';
import Scenes from './scenes/Scenes';

export default function Main() {
    const { loading } = useLoading();

    return (
        <MainLayout>
            <Stack
                alignItems="center"
                justifyContent="center"
                component={motion.div}
                initial={{ height: '100%' }}
                animate={{ height: loading ? 500 : 280 }}
            >
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    component={motion.div}
                    initial={{
                        width: 360,
                        flex: 1,
                    }}
                    animate={{
                        width: loading ? 360 : 120,
                    }}
                >
                    <AnimatedAddie size={'100%'} />
                </Stack>
            </Stack>

            {!loading && (
                <Stack
                    sx={{
                        flex: 1,
                        width: '100%',
                        maxWidth: 728,
                        margin: '0 auto',
                    }}
                    component={motion.div}
                    id="scenes-wrap"
                >
                    <Scenes />
                </Stack>
            )}
        </MainLayout>
    );
}
