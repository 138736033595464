import { Box } from '@mui/material';
import React from 'react';

const shapes = [
    '',
    'M 100 600 q 0 -500, 500 -500 t 500 500 t -500 500 T 100 600 z',
    'M 100 600 q -50 -400, 500 -500 t 450 550 t -500 500 T 100 600 z',
    'M 100 600 q 0 -400, 500 -500 t 400 500 t -500 500 T 100 600 z',
    'M 150 600 q 0 -600, 500 -500 t 500 550 t -500 500 T 150 600 z',
    'M 150 600 q 0 -600, 500 -500 t 500 550 t -500 500 T 150 600 z',
    'M 100 600 q 100 -600, 500 -500 t 400 500 t -500 500 T 100 600 z',
];

const palette = {
    '--bg-0': '#B2E6E2',
    '--bg-1': '#176E65',
    '--blob-1': '#1B414C',
    '--blob-2': '#21CDB2',
    '--blob-3': '#093134',
    '--blob-4': '#24CFB6',
};

const keyframes = {
    '@keyframes blobAnim1': {
        '0%': {
            d: `path('${shapes[1]}')`,
        },
        '30%': {
            d: `path('${shapes[2]}')`,
        },
        '70%': {
            d: `path('${shapes[3]}')`,
        },
        '100%': {
            d: `path('${shapes[4]}')`,
        },
    },

    '@keyframes blobAnim2': {
        '0%': {
            d: `path('${shapes[3]}')`,
        },
        '40%': {
            d: `path('${shapes[5]}')`,
        },
        '80%': {
            d: `path('${shapes[2]}')`,
        },
        '100%': {
            d: `path('${shapes[6]}')`,
        },
    },

    '@keyframes blobAnim3': {
        '0%': {
            d: `path('${shapes[2]}')`,
        },
        '35%': {
            d: `path('${shapes[4]}')`,
        },
        '75%': {
            d: `path('${shapes[6]}')`,
        },
        '100%': {
            d: `path('${shapes[3]}')`,
        },
    },

    '@keyframes blobAnim4': {
        '0%': {
            d: `path('${shapes[4]}')`,
        },
        '30%': {
            d: `path('${shapes[6]}')`,
        },
        '70%': {
            d: `path('${shapes[2]}')`,
        },
        '100%': {
            d: `path('${shapes[5]}')`,
        },
    },

    '@keyframes rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
};

export default function AnimatedAddie({ size = 200 }) {
    return (
        <Box
            sx={{
                ...palette,
                ...keyframes,

                width: size,
                maxWidth: '100%',
                aspectRatio: '1 / 1',
            }}
        >
            <Box
                sx={{
                    '.blobs': {
                        width: '100%',
                        height: '100%',
                        maxHeight: '100%',
                        maxWidth: '100%',

                        svg: {
                            position: 'relative',
                            height: '100%',
                            zIndex: 2,
                        },

                        '.blob': {
                            animation:
                                'rotate 25s infinite alternate ease-in-out',
                            transformOrigin: '50% 50%',
                            opacity: 0.7,

                            path: {
                                animation: 'blobAnim1 5s infinite alternate',
                                cubicBezier: '(0.45, 0.2, 0.55, 0.8)',
                                transformOrigin: '50% 50%',
                                transform: 'scale(0.8)',

                                transition: 'fill 800ms ease',
                            },

                            '&.alt': {
                                animationDirection: 'alternate-reverse',
                                opacity: 0.3,
                            },
                        },

                        '.blob-1': {
                            path: {
                                fill: 'var(--blob-1)',
                                filter: 'blur(1rem)',
                            },
                        },

                        '.blob-2': {
                            animationDuration: '18s',
                            animationDirection: 'alternate-reverse',

                            path: {
                                fill: 'var(--blob-2)',
                                animationName: 'blobAnim2',
                                animationDuration: '7s',
                                filter: 'blur(0.75rem)',
                                transform: 'scale(0.78)',
                            },

                            '&.alt': {
                                animationDirection: 'alternate',
                            },
                        },

                        '.blob-3': {
                            animationDuration: '23s',

                            path: {
                                fill: 'var(--blob-3)',
                                animationName: 'blobAnim3',
                                animationDuration: '6s',
                                filter: 'blur(0.5rem)',
                                transform: 'scale(0.76)',
                            },
                        },

                        '.blob-4': {
                            animationDuration: '31s',
                            animationDirection: 'alternate-reverse',
                            opacity: 0.9,

                            path: {
                                fill: 'var(--blob-4)',
                                animationName: 'blobAnim4',
                                animationDuration: '10s',
                                filter: 'blur(10rem)',
                                transform: 'scale(0.5)',
                            },

                            '&.alt': {
                                animationDirection: 'alternate',
                                opacity: 0.8,
                            },
                        },
                    },
                }}
            >
                <div className="container palette-1">
                    <div className="blobs">
                        <svg viewBox="0 0 1200 1200">
                            <g className="blob blob-1">
                                <path />
                            </g>
                            <g className="blob blob-2">
                                <path />
                            </g>
                            <g className="blob blob-3">
                                <path />
                            </g>
                            <g className="blob blob-4">
                                <path />
                            </g>
                            <g className="blob blob-1 alt">
                                <path />
                            </g>
                            <g className="blob blob-2 alt">
                                <path />
                            </g>
                            <g className="blob blob-3 alt">
                                <path />
                            </g>
                            <g className="blob blob-4 alt">
                                <path />
                            </g>
                        </svg>
                    </div>
                </div>
            </Box>
        </Box>
    );
}
