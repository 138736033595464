import config from './config';

const apiUrl = config.apiUrl;

export const fetchScript = (opts) => {
    let query = '';

    if (opts) {
        query = '?'; // + 'name=' + opts.name;

        for (let key in opts) {
            query += `${key}=${opts[key]}&`;
        }
    }

    return fetch(apiUrl + `/chapters${query}`).then((response) =>
        response.json()
    );
};

export const fetchToken = () => {
    return fetch(apiUrl + '/token').then((response) => response.json());
};
