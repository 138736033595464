import { useContext, createContext, useEffect, useState } from 'react';
import { fetchScript } from '../lib/api';

const ScriptContext = createContext({});

export const ScriptProvider = ({ children }) => {
    const [script, setScript] = useState([]);
    const [scriptLoading, setScriptLoading] = useState(true);
    const [activeScene, setActiveScene] = useState(null);

    const [answers, setAnswers] = useState({});

    // log state changes
    useEffect(() => {
        console.log('[ScriptProvider]: ', {
            script,
        });
    }, [script]);

    // onload - fetch scenes
    useEffect(() => {
        getScript();
    }, []);

    // TODO:
    // - active scene
    // - get next scene
    // - on name update, re-fetch scenes

    const getScript = async () => {
        setScriptLoading(true);

        const res = await fetchScript();

        if (res.success) {
            setScript(res.data);
        }

        const firstScene = res.data[0].scenes[0];
        setActiveScene(firstScene);
        setScriptLoading(false);
    };

    const refetchScript = async (opts) => {
        const newAnswers = {
            ...answers,
            ...opts,
        };

        setAnswers(newAnswers);

        const res = await fetchScript(newAnswers);

        if (res.success) {
            setScript(res.data);
            return true;
        }

        console.error(res);
        return false;
    };

    const goto = (sceneId) => {
        for (let i = 0; i < script.length; i++) {
            const chapter = script[i];
            if (chapter.scenes) {
                for (let j = 0; j < chapter.scenes.length; j++) {
                    const scene = chapter.scenes[j];
                    if (scene.scene_id === sceneId) {
                        setActiveScene(scene);
                        break;
                    }
                }
            }
        }
    };

    return (
        <ScriptContext.Provider
            value={{
                script,
                scriptLoading,
                activeScene,
                goto,
                refetchScript,
            }}
        >
            {children}
        </ScriptContext.Provider>
    );
};

export default function useScript() {
    return useContext(ScriptContext);
}
