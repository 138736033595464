import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import useLayout from '../../contexts/LayoutContext';

export default function Footer() {
    const theme = useTheme();
    const { setFooterHeight } = useLayout();
    const container = useRef();

    useEffect(() => {
        function calcHeight() {
            if (!container.current) return;

            const { height } = container.current.getBoundingClientRect();
            setFooterHeight(height);
        }

        window.addEventListener('resize', calcHeight);
        calcHeight();

        return () => {
            window.removeEventListener('resize', calcHeight);
        };
    }, []);

    return (
        <Box
            ref={container}
            sx={{
                p: 2,
                backgroundColor: theme.palette.primaryContainer.main,
                color: theme.palette.primaryContainer.contrastText,
                textAlign: 'center',
            }}
        >
            <Typography variant="body2">
                GroundTruth © 2023. All rights reserved. <a href="#">Terms</a> •{' '}
                <a href="#">Privacy Policy</a>
            </Typography>
        </Box>
    );
}
