import React, { useState } from 'react';
import {
    Stack,
    TextField,
    InputAdornment,
    IconButton,
    Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as ClearIcon } from '../../assets/icons/ClearIcon.svg';
import theme from '../../theme/theme';

export default function ChatInput({
    value,
    setValue,
    onSubmit,
    buttonText = 'Send',
    startAdornment = null,
}) {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        await onSubmit();

        setLoading(false);
    };

    return (
        <Stack direction="row" component="form" onSubmit={handleSubmit}>
            <TextField
                autoComplete="off"
                autoFocus
                color="primary"
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderTopRightRadius: '0 !important',
                        borderBottomRightRadius: '0 !important',
                    },
                }}
                placeholder="type here..."
                InputProps={{
                    autoComplete: 'off',
                    startAdornment: startAdornment ? (
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: '400 !important',
                                fontFamily: `${theme.typography.fontFamilyRoboto} !important`,
                                color: 'primary.main',
                            }}
                        >
                            {startAdornment}&nbsp;
                        </Typography>
                    ) : null,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setValue('')}>
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <LoadingButton
                disabled={!value}
                loading={loading}
                variant="contained"
                color="primary"
                sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
                type="submit"
            >
                {buttonText}
            </LoadingButton>
        </Stack>
    );
}
