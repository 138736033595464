import { useContext, createContext, useState, useEffect } from 'react';
import { fetchToken } from '../lib/api';

const AppSettingsContext = createContext({});

export const AppSettingsProvider = ({ children }) => {
    const [muted, setMuted] = useState(false);

    const [token, setToken] = useState({
        loading: true,
        value: null,
        exp: null,
        region: null,
    });

    // onload - get token
    useEffect(() => {
        getToken();
    }, []);

    // log any changes
    useEffect(() => {
        console.log('[AppSettingsProvider]', {
            muted,
            token,
        });
    }, [muted, token]);

    const getToken = async () => {
        let now = Math.floor(Date.now() / 1000);

        if (!token.value || !token.exp || token.exp - now < 360) {
            const data = await fetchToken();

            if (data.success) {
                const token = data.data.token;
                const authTokenObj = JSON.parse(atob(token.split('.')[1]));

                const newToken = {
                    loading: false,
                    value: token,
                    exp: authTokenObj.exp,
                    region: authTokenObj.region,
                };

                setToken(newToken);

                setTimeout(() => {
                    getToken();
                }, 540000);
            } else {
                console.error('[getToken error]: ', data);
            }
        }

        return token;
    };

    return (
        <AppSettingsContext.Provider
            value={{
                muted,
                setMuted,
                token,
            }}
        >
            {children}
        </AppSettingsContext.Provider>
    );
};

export default function useAppSettings() {
    return useContext(AppSettingsContext);
}
