import { useContext, createContext, useState, useEffect } from 'react';
import useAppSettings from './AppSettingsContext';
const SpeechSDK = require('microsoft-cognitiveservices-speech-sdk');

const SpeechSDKSpeech = SpeechSDK;
const SpeechContext = createContext({});

export const SpeechProvider = ({ children }) => {
    const { token, muted } = useAppSettings();

    const [audioContext, setAudioContext] = useState(null);
    const [gainNode, setGainNode] = useState(null);

    useEffect(() => {
        const ctx = new AudioContext();
        const ctxGainNode = ctx.createGain();
        ctxGainNode.connect(ctx.destination);

        setAudioContext(ctx);
        setGainNode(ctxGainNode);
    }, []);

    useEffect(() => {
        if (!gainNode) return;

        if (muted) {
            gainNode.gain.value = 0;
        } else {
            gainNode.gain.value = 1;
        }
    }, [muted, gainNode]);

    function GetSpeechConfig() {
        if (!token.value) {
            console.error('[SpeechProvider] no token found');
            return;
        }

        let speechConfig;
        if (token.value) {
            speechConfig = SpeechSDKSpeech.SpeechConfig.fromAuthorizationToken(
                token.value,
                token.region
            );
        }
        return speechConfig;
    }

    async function Speak(ssmlInput, onSpeakEnded) {
        if (!audioContext)
            return console.error('[SpeechProvider] no audio context found');

        const speechConfig = GetSpeechConfig(true);
        var stream = SpeechSDKSpeech.AudioOutputStream.createPullStream();
        var speechAudioConfig =
            SpeechSDKSpeech.AudioConfig.fromStreamOutput(stream);

        const synthesizer = new SpeechSDKSpeech.SpeechSynthesizer(
            speechConfig,
            speechAudioConfig
        );

        let successHandler = function (result) {
            if (result.reason === SpeechSDK.ResultReason.Canceled) {
                console.error(
                    '[SpeechProvider] Synthesis failed. Error detail: ' +
                        result.errorDetails
                );
                return;
            }

            const source = audioContext.createBufferSource();
            audioContext.decodeAudioData(result.audioData, function (buffer) {
                source.buffer = buffer;
                source.connect(gainNode);

                if (onSpeakEnded && typeof onSpeakEnded === 'function') {
                    source.onended = onSpeakEnded;
                }
                source.start(0);
            });
            audioContext.onstatechange = () => {
                if (
                    audioContext.state === 'suspended' &&
                    source &&
                    source.stop
                ) {
                    source.stop();
                    source.onended = undefined;
                }
            };
            audioContext.resume();
        };

        synthesizer.speakSsmlAsync(ssmlInput, successHandler);
    }

    return (
        <SpeechContext.Provider
            value={{
                Speak,
            }}
        >
            {children}
        </SpeechContext.Provider>
    );
};

export default function useSpeech() {
    return useContext(SpeechContext);
}
