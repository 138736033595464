import React, { useState } from 'react';
import useScript from '../../../contexts/ScriptContext';
import ChatInput from '../../common/ChatInput';

export default function TheWebsiteField({ branches }) {
    const [url, setUrl] = useState('');

    const { refetchScript, goto } = useScript();

    const handleNext = async () => {
        const done = await refetchScript({ website_url: url });

        if (done) {
            goto(branches[0].goto);
        }
    };

    return (
        <ChatInput
            value={url}
            setValue={setUrl}
            onSubmit={handleNext}
            startAdornment="https://"
        />
    );
}
