import React, { useState } from 'react';
import useScript from '../../contexts/ScriptContext';

import { Button, Box, Stack } from '@mui/material';
import ActiveScene from './ActiveScene';
import ScriptToolbox from './ScriptToolbox';

export default function Scenes() {
    const { activeScene } = useScript();

    const [userInteracted, setUserInteracted] = useState(false);

    return (
        <Stack
            justifyContent="flex-end"
            alignItems="center"
            sx={{ flex: 1 }}
            id="Scenes"
        >
            {!userInteracted ? (
                <Box sx={{ p: 4 }}>
                    <Button
                        variant="contained"
                        onClick={() => setUserInteracted(true)}
                    >
                        Start
                    </Button>
                </Box>
            ) : (
                <>
                    <ActiveScene
                        scene={activeScene}
                        key={activeScene.scene_id}
                    />

                    <ScriptToolbox />
                </>
            )}
        </Stack>
    );
}
