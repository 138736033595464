import React, { useEffect } from 'react';
import TheButton from './components/TheButton';
import { Box, Divider } from '@mui/material';
import theme from '../../theme/theme';
import TheName from './components/TheName';
import TheAdType from './components/TheAdType';
import TheWebsiteField from './components/TheWebsiteField';

export default function ComponentInterpreter({
    component,
    onMounted,
    ...other
}) {
    useEffect(() => {
        onMounted();
    }, []);

    return (
        <Box>
            <Divider sx={{ mb: 2, borderColor: theme.palette.outline.main }} />

            {component === 'button' && <TheButton {...other} />}
            {component === 'name' && <TheName {...other} />}
            {component === 'ad_type' && <TheAdType {...other} />}
            {component === 'website_url' && <TheWebsiteField {...other} />}
        </Box>
    );
}
