import { createTheme } from '@mui/material/styles';

const defaultPalette = {
    primary: {
        main: '#20D2B5',
        contrastText: '#0A3F36',
    },
    secondary: {
        main: '#53A8BC',
        contrastText: '#ffffff',
    },
    primaryContainer: {
        main: '#E9FAF8',
        contrastText: '#0A3F36',
    },
    secondaryContainer: {
        main: '#DDEEF2',
        contrastText: '#193238',
    },
    outline: {
        main: '#BCF1E9',
    },
    links: {
        main: '#009CEC',
    },
    white: {
        main: '#fff',
    },
};

const defaultTheme = createTheme();

const fontFamilyPoppins = [
    'Poppins',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
].join(',');

const fontFamilyRoboto = [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
].join(',');

// A custom theme for this app
const theme = createTheme({
    palette: {
        ...defaultPalette,
    },

    typography: {
        fontFamily: fontFamilyRoboto,
        fontFamilyPoppins,
        fontFamilyRoboto,

        h1: {
            fontSize: 63,
            fontWeight: 700,
            fontFamily: fontFamilyPoppins,
        },

        body1: {
            fontFamily: fontFamilyRoboto,

            [defaultTheme.breakpoints.up('md')]: {
                fontFamily: fontFamilyPoppins,
                fontWeight: 700,
            },
        },

        button: {
            fontFamily: fontFamilyPoppins,
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'none',

            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 24,
                fontWeight: 700,
            },
        },
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    a: {
                        color: defaultPalette.links.main,
                        textDecoration: 'none',
                    },
                },
            },
        },

        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: 8,
                },

                containedPrimary: {
                    background: defaultPalette.primary.main,
                    color: defaultPalette.primary.contrastText,
                },

                sizeLarge: {
                    fontWeight: 700,
                    fontSize: 14,
                    padding: '12px 24px',

                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 24,
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& input': {
                        fontFamily: fontFamilyRoboto,
                        fontWeight: 400,
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: 12,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px !important',
                    },
                },

                colorPrimary: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: defaultPalette.outline.main,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${defaultPalette.primary.main} !important`,
                    },
                    '&.Mui-focused': {
                        background: defaultPalette.primaryContainer.main,
                    },
                },
            },
        },

        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    border: `1px ${defaultPalette.outline.main} solid`,
                },
            },
        },
    },
});

export default theme;
