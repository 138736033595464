import { useContext, createContext } from 'react';

const SummaryContext = createContext({});

export const SummaryProvider = ({ children }) => {
    const summary = 'a';

    // TODO:
    // tracks the progress of the script
    // onload - get the abandoned progress from the LS
    // on push - save the progress to LS

    return (
        <SummaryContext.Provider
            value={{
                summary,
            }}
        >
            {children}
        </SummaryContext.Provider>
    );
};

export default function useSummary() {
    return useContext(SummaryContext);
}
