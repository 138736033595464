import React from 'react';
import { Box, Stack } from '@mui/material';
import Header from './Header';
import useLayout from '../../contexts/LayoutContext';
import Footer from './Footer';
import AnimatedAddie from '../common/AnimatedAddie';

export default function MainLayout({ children }) {
    const { headerHeight, footerHeight } = useLayout();

    return (
        <Box
            sx={{
                position: 'relative',
                overflowX: 'hidden',
            }}
        >
            <Header />
            <Stack
                component="main"
                sx={{
                    marginTop: `${headerHeight}px`,
                    minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
                    height: '100%',
                }}
            >
                {children}
            </Stack>
            <Footer />
        </Box>
    );
}
