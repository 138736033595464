import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SpeechBubble from './SpeechBubble';
import { motion } from 'framer-motion';
import ComponentInterpreter from './ComponentInterpreter';

export default function ActiveScene({ scene }) {
    const [visiblePrompts, setVisiblePrompts] = useState([]);

    useEffect(() => {
        setVisiblePrompts([scene.prompts[0]]);
    }, []);

    const playNextPrompt = () => {
        if (visiblePrompts.length < scene.prompts.length) {
            const nextPromptIndex = visiblePrompts.length;
            setVisiblePrompts((val) => [
                ...val,
                scene.prompts[nextPromptIndex],
            ]);
        }
    };

    const afterComponentMounts = () => {
        setTimeout(() => {
            playNextPrompt();
        }, 1000);
    };

    return (
        <Stack
            justifyContent="flex-end"
            sx={{ flex: 1, width: '100%', mb: 2 }}
            component={motion.div}
            layout
            id="ActiveScene-Stack"
        >
            {/* MAP SPEECH CLOUDS */}
            {visiblePrompts
                .filter((el) => el.type === 'speech')
                .map((prompt, index) => (
                    <Box
                        key={index}
                        component={motion.div}
                        layout
                        initial={{
                            opacity: 0,
                            height: 0,
                            y: '100%',
                        }}
                        animate={{
                            opacity: 1,
                            height: 'auto',
                            y: 0,
                        }}
                    >
                        <SpeechBubble
                            prompt={prompt}
                            onSpeechEnded={playNextPrompt}
                        />
                    </Box>
                ))}

            {/* MAP COMPONENTS */}
            {visiblePrompts
                .filter((el) => el.type === 'component')
                .map((prompt, index) => (
                    <Box
                        key={index}
                        component={motion.div}
                        layout
                        initial={{
                            opacity: 0,
                            height: 0,
                            y: '100%',
                        }}
                        animate={{
                            opacity: 1,
                            height: 'auto',
                            y: 0,
                        }}
                    >
                        <ComponentInterpreter
                            component={prompt.id}
                            onMounted={afterComponentMounts}
                            branches={scene.branches}
                        />
                    </Box>
                ))}
        </Stack>
    );
}
