import React from 'react';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Stack,
    Typography,
} from '@mui/material';
import { ReactComponent as WebIcon } from '../../../assets/icons/WebIcon.svg';
import { ReactComponent as StorefrontIcon } from '../../../assets/icons/StorefrontIcon.svg';
import theme from '../../../theme/theme';
import useScript from '../../../contexts/ScriptContext';

export default function TheAdType({ branches }) {
    const { goto } = useScript();

    const handleClick = (option) => {
        const branch = branches.find((el) => el.match === option);
        goto(branch.goto);
    };

    return (
        <Box>
            <Stack direction="row" spacing={3}>
                <Box sx={{ flex: 1 }}>
                    <AdCard
                        icon={<WebIcon />}
                        title="A"
                        copy="Website"
                        onClick={() => handleClick('website')}
                    />
                </Box>
                <Box sx={{ flex: 1 }}>
                    <AdCard
                        icon={<StorefrontIcon />}
                        title="B"
                        copy="Store"
                        onClick={() => handleClick('store')}
                    />
                </Box>
            </Stack>
        </Box>
    );
}

function AdCard({ icon, title, copy, onClick }) {
    return (
        <Card
            onClick={onClick}
            sx={{
                '&:hover': {
                    borderColor: 'secondary.main',
                    cursor: 'pointer',
                    borderWidth: 4,

                    '.MuiCardMedia-root': {
                        backgroundColor: 'secondary.main',
                        height: 165,
                        color: 'white.main',
                    },
                    '.MuiCardContent-root': {
                        backgroundColor: 'secondaryContainer.main',
                        padding: '13px !important',
                        paddingBottom: '21px !important',
                        height: 165,
                    },
                },
            }}
        >
            <CardMedia
                sx={{
                    backgroundColor: 'outline.main',
                    height: 168,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </CardMedia>
            <CardContent
                sx={{
                    height: 168,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}
            >
                <Typography variant="h1">{title}</Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontFamily: `${theme.typography.fontFamily} !important`,
                        fontWeight: '400 !important',
                    }}
                >
                    {copy}
                </Typography>
            </CardContent>
        </Card>
    );
}
