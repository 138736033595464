import React from 'react';
import useScript from '../../contexts/ScriptContext';
import { Button, Box } from '@mui/material';

export default function ScriptToolbox() {
    const { script, goto } = useScript();

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '50%',
                left: 0,
                width: 160,
                background: '#e7e7e9',
                transform: 'translateY(-50%)',
                p: 1,
            }}
        >
            <Box>SCRIPT:</Box>
            <Box>
                {script.map((chapter) => (
                    <Box key={chapter.chapter}>
                        <Box>Chapter {chapter.chapter}</Box>
                        <Box sx={{ display: 'flex' }}>
                            {chapter.scenes.map((scene) => (
                                <Box key={scene.scene_id}>
                                    <Button
                                        size="small"
                                        sx={{
                                            p: 1,
                                            minWidth: 20,
                                        }}
                                        onClick={() => goto(scene.scene_id)}
                                    >
                                        <Box sx={{ fontSize: 15 }}>
                                            {scene.scene_id}
                                        </Box>
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
