import './reset.css';
import theme from './theme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ScriptProvider } from './contexts/ScriptContext';
import { SummaryProvider } from './contexts/SummaryContext';
import { SceneProvider } from './contexts/SceneContext';
import { LayoutProvider } from './contexts/LayoutContext';
import { AppSettingsProvider } from './contexts/AppSettingsContext';
import Main from './components/Main';
import { SpeechProvider } from './contexts/SpeechContext';
import { LoadingProvider } from './contexts/LoadingContext';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AppSettingsProvider>
                <LayoutProvider>
                    <ScriptProvider>
                        <SummaryProvider>
                            <SpeechProvider>
                                <SceneProvider>
                                    <LoadingProvider>
                                        <CssBaseline />
                                        <Main />
                                    </LoadingProvider>
                                </SceneProvider>
                            </SpeechProvider>
                        </SummaryProvider>
                    </ScriptProvider>
                </LayoutProvider>
            </AppSettingsProvider>
        </ThemeProvider>
    );
}

export default App;
